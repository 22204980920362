
/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />
/// <reference path="../../services/users.services.ts" />

class rolesCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService'];
    showsubflows: boolean;
    genericServices: GenericServiceClass
    rolesEntities:any[];
    userServices: UsersServiceClass;
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory

    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'users';
        var user: any = this.socketService.user;
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        this.genericServices = new GenericServiceClass(this.service.socket, this.service.namespace)

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }
        this.basequery = "_type eq 'role'";
        this.socketService.onSignedin(() => {
            // this.loaddata();
            this.getRolesPagedData();
        });
    }

    searchInRoles() {
        this.loading = true;
        this.rolesEntities = [];
        this.page = 1;
        //this.loaddata();
        this.getRolesPagedData();
        this.loading = false;
    }
    async getRolesPagedData() {
        this.loading = true;
        clearError(this.$scope);
        this.service = this.socketService.namespace;

        if (!this.genericServices.isConnected()) return;

        let query: any = this.basequery;
        if (this.searchstring == null || this.searchstring == '') {
            if (query) {
                if (query.name) {
                    delete query.name;
                }
                if (query.$or) {
                    delete query.$or;
                }
            }
        }

        // prepare search query 
        if (this.searchstring !== '') {
            query += " and contains(display_name,'" + this.searchstring + "')"
        }

        try {
            let projection = { 'display_name': 1, 'members': 1, 'description': 1 };
            var Result: any = await this.userServices.getRolesPageService(query, projection, this.pagesize, (this.page - 1) * this.pagesize, this.order.field, this.order.reverse, this.collection, false);
            this.rolesEntities = Result.items;
            //to get the workflows for the loaded projects ids
            var QueuesIds = this.rolesEntities.map(P => P._id);
            var myquery: any = {};

            myquery.QueueId = { $in: QueuesIds };
            this.totalItems = Result.count;
            this.pageCount = Math.ceil(Result.count / this.pagesize);
            if (this.pageCount < this.page) {
                this.page = this.pageCount;
            }
            this.loading = false;
            this.scrollloading = (this.rolesEntities.length > 0);
            this.loading = false;
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            handleError(this.$scope, err);
        }
    }
    GetRolesData(pageNumber : number){
        this.page = pageNumber;
       // this.socketService.onSignedin(() => {
            this.getRolesPagedData();            
      //  });
    }
}

class roleCtrl {
    static $inject: Array<string> = ['$scope', '$location', 'socketService', '$routeParams', '$timeout', 'toastr'];
    user: any;
    optionsList: any;
    selectedList: any;
    id: string;
    model: any;
    loading: boolean;
    users: any[];
    toastrObj: any;
    limit: number = globalLoadData;
    genericServices: GenericServiceClass;
    userServices: UsersServiceClass;
    LicienseStatus: boolean;
    unattendedRoleId: any = unattendedrobotRoleId;
    attendedRoleId: any = attendedrobotRoleId;
    rpaDesignerRoleId: any = rpaDesignerRoleId;

    constructor(
        public $scope,
        public $location,
        public socketService: socketServiceFactory,
        public $routeParams,
        public $timeout,
        public toastr
    ) {
        this.toastrObj = toastr;
        this.id = this.$routeParams.id;
        var user: any = this.socketService.user;
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
        this.userServices = new UsersServiceClass(this.socketService.namespace.socket, this.socketService.namespace)
     
        // check the license status of the current user 
        // if user have license and the role is on of(attended , unattende or rpadesigners)
        // then disable adding new members
        // this.genericServices.getLisenseStatus().then((res:any)=>{

        //     if (res.activeLicense) {
        //         this.LicienseStatus = res.activeLicense;
        //     } else {
        //         this.LicienseStatus = false;
        //     }
        // })

        if (!user.roles.find(R => R._id == adminsRoleId)) {
            this.$location.path("/")
            this.$scope.$apply();
        }




        this.socketService.onSignedin(async () => {
            //var query = "(_type eq 'user' or _type eq 'role')";
            var query = "(_type eq 'user' and username ne 'iRoboadmin' and username ne 'irobo.super@admin')";
            if (this.id) {
                query += " and _id ne '" + this.id + "'";
            }
            try {

                var items = await this.genericServices.get(query, this.limit, null, null, null, 'users');
                this.users = [];
                items.forEach(async member => {
                    // check if user has role of robot roles will not added to the selection list
                    if ((this.id == rpaDesignerRoleId || this.id == unattendedrobotRoleId || this.id == attendedrobotRoleId) && await this.IsUserHaveRelatedRole(member._id)) {
                        // user has robot role will not added 
                        // do nothing
                    } else {
                        this.users.push({ name: member.name, _id: member._id })
                    }
                });
                if (this.id) {
                    await this.loaddata(this.id);


                }
                else {
                    this.model = {
                        _type: 'role',
                        members: [],
                        name: '',
                        username: '',
                        password: ''
                    };
                }
            } catch (err) {
                // handleError(this.$scope, err);
                this.toastrObj.error(GetErrorMessage(err));
            }
        });
    }
    goback() {
        this.$location.path("/main");
    }



    // check the user role type
    async IsUserHaveRelatedRole(user_id) {
        try {
            var query = {}
            // check if attended robot

            query = { _id: attendedrobotRoleId, members: { $elemMatch: { _id: user_id } } };
            var attended_items = await this.genericServices.query(query, { _id: 1, _type: 1, name: 1 }, 1, 0, null, null, 'users');

            // check if unattended robot
            query = { _id: unattendedrobotRoleId, members: { $elemMatch: { _id: user_id } } };
            var unattended_items = await this.genericServices.query(query, { _id: 1, _type: 1, name: 1 }, 1, 0, null, null, 'users');


            // check if robot
            query = { _id: rpaDesignerRoleId, members: { $elemMatch: { _id: user_id } } };
            var robots_items = await this.genericServices.query(query, { _id: 1, _type: 1, name: 1 }, 1, 0, null, null, 'users');

            if (robots_items.length > 0 || attended_items.length > 0 || unattended_items.length > 0) {
                return true;
            } else {
                return false;
            }



        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
            return false;
        }

    }
    async loaddata(id) {
        try {
            this.loading = true;
            var query = "_id eq '" + this.id + "'";
            var items = await this.genericServices.get(query, 1, null, null, null, 'users');
            this.loading = false;
            if (items.length == 0) {
                this.toastrObj.error(GetErrorMessage({ error: { message: 'No such user found ' + id } }));
                return;
            }

            this.model = items[0];
            if (!this.model.members) {
                this.model.members = [];
            }
            this.model.members.forEach(async member => {
                // check if user has role of robot roles will not added to the selection list
                if (member._id == superAdminId){
                    let index = this.model.members.indexOf(member);
                    this.model.members.splice(index,1);
                }
            });
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
            this.focusfirstinput();





        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));

        }
    }
    async update() {
        this.loading = true;
        this.model.members.forEach((member, index) => {
            delete this.model.members[index].$$hashKey;
        });
        try {

            await this.userServices.AddUserRole(this.model);
            this.loading = false;
            this.$location.path("/roles");
            if (!this.$scope.$$phase) { this.$scope.$apply(); }
        } catch (err) {
            this.loading = false;
            this.toastrObj.error(GetErrorMessage(err));
            //handleError(this.$scope, err);
        }
    };
    focusfirstinput() {
        let data = this.model;
        if (!data)
            return;
        if (!data.ui)
            return;
        if (data.ui.length == 0)
            return;
        var name = data.ui[0].name;
        this.$timeout(() => {
            this.$scope.$broadcast(name, null);
        }, 50);
    };
    keypress(event, e2, e3) {
        event.preventDefault();
    }
}